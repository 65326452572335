import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppConfig } from './config/app.config';
import { PharHttp } from './http/http-client.service';
import { UtilsService } from './utils.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [AppConfig, PharHttp, UtilsService],
})
export class CoreModule {}
